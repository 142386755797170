<template>
    <v-slide-x-transition hide-on-leave>
        <v-card v-if="isCurrent" flat class="overflow-y-auto" min-height="70vh" width="100%" tile>
            <v-card flat class="my-0 pa-0 mx-auto" :max-width="(fluid || fluidTop || fluidContent || fluidBottom || isMobile) ? '100%' : '700px'" min-height="70vh">
                <slot name="title">
                    <!-- :style="(fluid || fluidTop || isMobile) ? null : 'width: 700px;'" -->
                    <v-card-title v-if="title != null || cShowRestart || cShowSkip" 
                        class="mx-auto" >
                        <v-icon v-if="icon != null" large left>{{ icon }}</v-icon>
                        {{ title }}
                        <BT-Btn
                            v-if="canNavigate && stage != null"
                            @click="previousStage"
                            :disabled="stage == null || stage == 0"
                            icon="mdi-chevron-left"
                            inline
                            title="Previous Stage" />

                        <BT-Btn
                            v-if="canNavigate && stage != null"
                            @click="nextStage"
                            disabled
                            icon="mdi-chevron-right"
                            inline
                            title="Next Stage" />

                        <v-slide-x-transition>
                            <BT-Btn
                                v-if="cShowRestart"
                                @click="restartStage"
                                :disabled="disabled"
                                inline
                                leftIcon="mdi-restart"
                                right
                                small
                                text
                                title="Restart Step Again" />
                        </v-slide-x-transition>
                        
                        <v-spacer />
                        
                        <BT-Btn v-if="cShowSkip" :disabled="disabled" inline text label="Skip" leftIcon="mdi-debug-step-over" @click="next" />
                        <BT-Btn v-if="showNext" :disabled="disabled" inline buttonClass="primary" label="Next" @click="$emit('next', next)" />
                    </v-card-title>
                </slot>
                <!--  :style="(fluid || fluidContent || isMobile) ? null : 'width: 700px;'" -->
                <div class="ma-4">
                    <slot name="body" v-bind:next="next">
                        {{ text }}
                    </slot>
                </div>
                <v-card-actions v-if="cShowYesNo || customizeTo != null" class="mx-auto">
                    <slot name="actions" v-bind:setup="step">
                        <BT-Btn
                            v-if="cShowYesNo"
                            buttonClass="primary mx-1"
                            inline
                            :label="noText"
                            @click="$emit('no', next)" />

                        <BT-Btn
                            v-if="cShowYesNo"
                            buttonClass="primary mx-1"
                            inline
                            :label="yesText"
                            @click="$emit('yes', next)" />

                        <BT-Btn
                            v-if="customizeTo != null"
                            class="primary--text"
                            inline
                            label="Customize"
                            text
                            :to="customizeTo"
                            verySmall />
                    </slot>
                </v-card-actions>
            </v-card>
            
            <v-overlay :value="loadingMsg != null" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>{{ loadingMsg || 'Loading' }}</p>
            </v-overlay>
        </v-card>
    </v-slide-x-transition>
</template>

<script>
export default {
    name: 'Setup-Step',
    data() {
        return {
            history: [],
            mStage: null
        }
    },
    props: {
        canNavigate: {
            type: Boolean,
            default: true
        },
        customizeTo: null,
        disabled: {
            type: Boolean,
            default: false
        },
        fluid: {
            type: Boolean,
            default: false
        },
        fluidBottom: {
            type: Boolean,
            default: false
        },
        fluidContent: {
            type: Boolean,
            default: false
        },
        fluidTop: {
            type: Boolean,
            default: false
        },
        hideRestart: {
            type: Boolean,
            default: false
        },
        hideSkip: {
            type: Boolean,
            default: false
        },
        hideYesNo: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        loadingMsg: {
            type: String,
            default: null
        },
        noText: {
            type: String,
            default: 'No'
        },
        onRestart: {
            type: Function,
            default: null
        },
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
        showNext: {
            type: Boolean,
            default: false
        },
        showSkip: {
            type: Boolean,
            default: false
        },
        showRestart: {
            type: Boolean,
            default: false
        },
        showYesNo: {
            type: Boolean,
            default: false
        },
        stage: {
            type: Number,
            default: null
        },
        step: null,
        stepSubtitle: {
            type: String,
            default: null
        },
        stepTitle: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        to: null,
        yesText: {
            type: String,
            default: 'Yes'
        }
    },
    mounted() {
        this.mStage = this.stage;
    },
    watch: {
        stage(nVal, oVal) {
            if (oVal > 0 && nVal == 0) {
                this.history = [];
                this.$emit('restart');
            }

            if (nVal > oVal) {
                //add to history
                this.history.push(oVal);
            }

            this.mStage = nVal;
        }
    },
    computed: {
        canNext() {
            return this.settings?.data != null && (this.settings.data.currentIndex + 1) < this.settings.data.stepperPlan.length;
        },
        cShowRestart() {
            return !this.hideRestart && (this.showRestart || (this.stage != null && this.stage > 0));
        },
        cShowSkip() {
            return !this.hideSkip && (this.showSkip || this.stage == 0);
        },
        cShowYesNo() {
            return !this.hideYesNo && (this.showYesNo || this.stage == 0);
        },
        dataKey() {
            return `${this.$BlitzIt.auth.session.data?.companyAccountID}-setup-data`;
        },
        historyKey() {
            return `${this.$BlitzIt.auth.session.data?.companyAccountID}-setup-history`;
        },
        isCurrent() {
            return this.settings?.data != null && this.settings.data.currentIndex < this.settings.data.stepperPlan.length && this.step === this.settings.data.stepperPlan[this.settings.data.currentIndex];
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        next() {
            if (this.to != null) {
                if (this.to.query == null) {
                    this.to.query = {};
                }
                this.to.query.setup = this.settings.data.currentIndex;
                this.$router.push(this.to)
            }
            else {
                if (this.settings.data.currentIndex >= this.settings.data.stepperPlan.length - 1) {
                    this.settings.data.currentIndex = 0;
                    this.settings.data.currentStep = 'start';
                    this.settings.data.stepperPlan = ['start', 'locations', 'ordering-questions'];
                    this.settings.history = [];
                    this.saveLocally(this.dataKey, this.settings.data);
                    this.saveLocally(this.historyKey, this.settings.history);
                    
                    this.$router.push({ name: 'office-portal-home'});
                }
                else {
                    this.settings.history.unshift(this.copyDeep(this.settings.data));
                    this.settings.data.currentIndex += 1;
                    this.settings.data.currentStep = this.settings.data.stepperPlan[this.settings.data.currentIndex];

                    this.saveLocally(this.dataKey, this.settings.data);
                    this.saveLocally(this.historyKey, this.settings.history);
                }
            }
        },
        nextStage() {
            this.mStage += 1;
            this.$emit('update:stage', this.mStage);
        },
        previousStage() {
            if (this.isLengthyArray(this.history)) {
                this.mStage = this.history.length > 0 ? this.history[this.history.length - 1] : 0;
                this.$emit('update:stage', this.mStage);
                this.history.pop();
            }
        },
        restartStage() {
            if (this.onRestart != null) {
                this.onRestart();
            }
            else {
                this.mStage = 0;
                this.$emit('update:stage', this.mStage);
            }
        },
        skipToNext() {
            if (this.settings.data.currentIndex >= this.settings.data.stepperPlan.length - 1) {
                this.settings.data.currentIndex = 0;
                this.settings.data.currentStep = 'start';
                this.settings.data.stepperPlan = ['start', 'locations', 'ordering-questions'];
                this.settings.history = [];
                this.saveLocally(this.dataKey, this.settings.data);
                this.saveLocally(this.historyKey, this.settings.history);

                this.$router.push({ name: 'office-portal-home'});
            }
            else {
                this.settings.history.unshift(this.copyDeep(this.settings.data));
                this.settings.data.currentIndex += 1;
                this.settings.data.currentStep = this.settings.data.stepperPlan[this.settings.data.currentIndex];
                this.saveLocally(this.dataKey, this.settings.data);
                this.saveLocally(this.historyKey, this.settings.history);
            }
        },
        toggleMoreInfo() {
            this.settings.data.showMore = !this.settings.data.showMore;
        }
    }
}
</script>